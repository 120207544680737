import { ArrayVector, DataFrame, FieldType } from '@grafana/data';

const data: DataFrame[] = [
  {
    refId: 'Response Time',
    meta: undefined,
    length: 21,
    fields: [
      {
        name: 'time',
        type: FieldType.time,
        config: undefined,
        values: new ArrayVector([
          1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856,
          1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856,
          1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856,
        ]),
      },
      {
        name: 'destination',
        config: undefined,
        type: FieldType.string,
        values: new ArrayVector([
          '/chat-command-api/chat-command-state-processor',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/chat-command-state-processor',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/chat-command-state-processor',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/ftcp-state-reporter/command-management-service-b/chat-command-state-processor',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/ftcp-state-reporter/command-management-service-b/chat-command-state-processor',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter',
          '/chat-command-api/chat-command-state-processor/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/ftcp-state-reporter/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/ftcp-state-reporter/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/command-summary-service',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/command-summary-service',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/ftcp-state-reporter/command-management-service-b/command-summary-service',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/ftcp-state-reporter/command-management-service-b/command-summary-service',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/device-connectivity-service',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/ftcp-state-reporter',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/ftcp-state-reporter',
          '/chat-command-api/iam',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/_vsdn',
        ]),
      },
      {
        name: 'path',
        config: undefined,
        type: FieldType.string,
        values: new ArrayVector([
          '/chat-command-api',
          '/chat-command-api/chat-command-state-processor/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/ftcp-state-reporter/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/ftcp-state-reporter/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b',
          '/chat-command-api/chat-command-state-processor',
          '/chat-command-api/chat-command-state-processor/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/ftcp-state-reporter',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/ftcp-state-reporter',
          '/chat-command-api/chat-command-state-processor/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/ftcp-state-reporter/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/ftcp-state-reporter/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect',
          '/chat-command-api',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect',
        ]),
      },
      {
        name: 'to',
        config: {
          filterable: true,
        },
        type: FieldType.string,
        values: new ArrayVector([
          'chat-command-state-processor',
          'chat-command-state-processor',
          'chat-command-state-processor',
          'chat-command-state-processor',
          'chat-command-state-processor',
          'cloud-to-ftcp-converter',
          'command-management-service-b',
          '_enqueue',
          'command-management-service-b',
          'command-management-service-b',
          'command-management-service-b',
          'command-summary-service',
          'command-summary-service',
          'command-summary-service',
          'command-summary-service',
          'device-connectivity-service',
          'ftcp-state-reporter',
          'ftcp-state-reporter',
          'iam',
          'vsdn-connect',
          '_vsdn',
        ]),
      },
      {
        name: 'Value #Response Time',
        type: FieldType.number,
        config: undefined,
        values: new ArrayVector([
          900000.46496194694408, 30092.65486774209603, 1000.1881629392853212, 40000046.10983401644273,
          454.97455031475914, 0.5391195882323513, 10010.48385075479949, 38009.05405685538886, 5000232.296875153085,
          78.04364041730364, 9700.0362756718352, 30092.6548625986315, 1000.1881629948369974, 4000046.10982773206774,
          454.97454837544984, 23.96698102819716, 135.59865564458624, 1500091.2005754363759, 10000075.6182274945074,
          135.4516841841251, 1591.594995546215,
        ]),
      },
    ],
  },
  {
    refId: 'Error Rate',
    meta: undefined,
    length: 1,
    fields: [
      {
        name: 'Time',
        type: FieldType.time,
        config: {},
        values: new ArrayVector([1661804769856]),
      },
      {
        name: 'path',
        config: undefined,
        type: FieldType.string,
        values: new ArrayVector([
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/_vsdn',
        ]),
      },
      {
        name: 'to',
        config: undefined,
        type: FieldType.string,
        values: new ArrayVector(['_vsdn']),
      },
      {
        name: 'Value #Error Rate',
        type: FieldType.number,
        config: {},
        values: new ArrayVector([0.0009030944733188788]),
      },
    ],
  },
  {
    refId: 'Request Rate',
    meta: undefined,
    length: 21,
    fields: [
      {
        name: 'Time',
        type: FieldType.time,
        config: {},
        values: new ArrayVector([
          1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856,
          1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856,
          1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856,
        ]),
      },
      {
        name: 'path',
        config: undefined,
        type: FieldType.string,
        values: new ArrayVector([
          '/chat-command-api/chat-command-state-processor',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/chat-command-state-processor',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/ftcp-state-reporter/command-management-service-b/chat-command-state-processor',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/ftcp-state-reporter/command-management-service-b/chat-command-state-processor',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/chat-command-state-processor',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter',
          '/chat-command-api/chat-command-state-processor/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/ftcp-state-reporter/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/ftcp-state-reporter/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/ftcp-state-reporter/command-management-service-b/command-summary-service',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/ftcp-state-reporter/command-management-service-b/command-summary-service',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/command-summary-service',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/command-summary-service',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/device-connectivity-service',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/ftcp-state-reporter',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/ftcp-state-reporter',
          '/chat-command-api/iam',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/_vsdn',
        ]),
      },
      {
        name: 'to',
        config: undefined,
        type: FieldType.string,
        values: new ArrayVector([
          'chat-command-state-processor',
          'chat-command-state-processor',
          'chat-command-state-processor',
          'chat-command-state-processor',
          'chat-command-state-processor',
          'cloud-to-ftcp-converter',
          'command-management-service-b',
          '_enqueue',
          'command-management-service-b',
          'command-management-service-b',
          'command-management-service-b',
          'command-summary-service',
          'command-summary-service',
          'command-summary-service',
          'command-summary-service',
          'device-connectivity-service',
          'ftcp-state-reporter',
          'ftcp-state-reporter',
          'iam',
          'vsdn-connect',
          '_vsdn',
        ]),
      },
      {
        name: 'Value #Request Rate',
        type: FieldType.number,
        config: undefined,
        values: new ArrayVector([
          0.5670504339883714, 1.0258690529867516, 1.0258690529867516, 1.0257764282242068, 2.267600700633936,
          0.5129327310895855, 0.5670492220914154, 0.5670492220914154, 4.887117693069482, 0.5129331613707656,
          0.5128868489937828, 1.0258654606402504, 1.025772835886285, 1.0258654606402504, 2.267592909709672,
          0.00981822395573252, 0.5129327317598469, 0.5128864193839364, 0.5670487463334208, 0.5126675977163121,
          0.5127142630178316,
        ]),
      },
    ],
  },
  {
    refId: 'Outbound Request Rate',
    meta: undefined,
    length: 21,
    fields: [
      {
        name: 'Time',
        type: FieldType.time,
        config: undefined,
        values: new ArrayVector([
          1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856,
          1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856,
          1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856, 1661804769856,
        ]),
      },
      {
        name: 'destination',
        config: undefined,
        type: FieldType.string,
        values: new ArrayVector([
          '/chat-command-api/chat-command-state-processor',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/chat-command-state-processor',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/chat-command-state-processor',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/ftcp-state-reporter/command-management-service-b/chat-command-state-processor',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/ftcp-state-reporter/command-management-service-b/chat-command-state-processor',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter',
          '/chat-command-api/chat-command-state-processor/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/ftcp-state-reporter/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/ftcp-state-reporter/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/command-summary-service',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/command-summary-service',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/ftcp-state-reporter/command-management-service-b/command-summary-service',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/ftcp-state-reporter/command-management-service-b/command-summary-service',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/device-connectivity-service',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/ftcp-state-reporter',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/ftcp-state-reporter',
          '/chat-command-api/iam',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/_vsdn',
        ]),
      },
      {
        name: 'path',
        config: undefined,
        type: FieldType.string,
        values: new ArrayVector([
          '/chat-command-api',
          '/chat-command-api/chat-command-state-processor/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/ftcp-state-reporter/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/ftcp-state-reporter/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b',
          '/chat-command-api/chat-command-state-processor',
          '/chat-command-api/chat-command-state-processor/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/ftcp-state-reporter',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/ftcp-state-reporter',
          '/chat-command-api/chat-command-state-processor/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/ftcp-state-reporter/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect/ftcp-state-reporter/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect',
          '/chat-command-api',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter',
          '/chat-command-api/chat-command-state-processor/command-management-service-b/_enqueue/command-management-service-b/cloud-to-ftcp-converter/vsdn-connect',
        ]),
      },
      {
        name: 'to',
        config: {
          filterable: true,
        },
        type: FieldType.string,
        values: new ArrayVector([
          'chat-command-state-processor',
          'chat-command-state-processor',
          'chat-command-state-processor',
          'chat-command-state-processor',
          'chat-command-state-processor',
          'cloud-to-ftcp-converter',
          'command-management-service-b',
          '_enqueue',
          'command-management-service-b',
          'command-management-service-b',
          'command-management-service-b',
          'command-summary-service',
          'command-summary-service',
          'command-summary-service',
          'command-summary-service',
          'device-connectivity-service',
          'ftcp-state-reporter',
          'ftcp-state-reporter',
          'iam',
          'vsdn-connect',
          '_vsdn',
        ]),
      },
      {
        name: 'Value #Outbound Request Rate',
        type: FieldType.number,
        config: undefined,
        values: new ArrayVector([
          0.5670504339883714, 2.267600700633936, 1.0258690529867516, 1.0258690529867516, 1.0257764282242068,
          0.5129327310895855, 0.5670492220914154, 0.5670492220914154, 4.887117693069482, 0.5129331613707656,
          0.5128868489937828, 2.267592909709672, 1.0258654606402504, 1.0258654606402504, 1.025772835886285,
          0.00981822395573252, 0.5129327317598469, 0.5128864193839364, 0.5670487463334208, 0.5126675977163121,
          0.5127142630178316,
        ]),
      },
    ],
  },
];

export default data;
