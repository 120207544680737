import humanFormat from 'human-format';
import { ScaleValue, IntGraphMetrics, IntSelectionStatistics, TableContent } from 'types';
import _ from 'lodash';

const lessThanThousandthsDec = 4;
const standardDec = 1;
const opsRateScale = new humanFormat.Scale({
  'ops/s': 1,
  'K ops/s': 1000,
  'M ops/s': 1000000,
  'G ops/s': 1000000000,
});

const scaleValues: ScaleValue[] = [
  { unit: 'ms', factor: 1 },
  { unit: 's', factor: 1000 },
  { unit: 'mins', factor: 60000 },
];

function _getTimeScale(timeUnit: string) {
  const scale: any = {};
  for (const scaleValue of scaleValues) {
    scale[scaleValue.unit] = scaleValue.factor;
    if (scaleValue.unit === timeUnit) {
      return scale;
    }
  }
  return scale;
}

export function formatRateForDisplay(rate: number): string {
  if (rate !== undefined) {
    return humanFormat(_.round(rate, standardDec), { scale: opsRateScale, standardDec });
  } else {
    return humanFormat(0, { scale: opsRateScale, standardDec });
  }
}

export function formatTimeForDisplay(milliseconds: number, timeunit: string): string {
  const timeScale = new humanFormat.Scale(_getTimeScale(timeunit));
  if (milliseconds !== undefined) {
    return humanFormat(_.round(milliseconds, standardDec), { scale: timeScale, standardDec });
  } else {
    return humanFormat(0, { scale: opsRateScale, standardDec });
  }
}

export function createSelectionStatistics(metrics: IntGraphMetrics, timeUnit: string): IntSelectionStatistics {
  const requestCount = _.defaultTo(metrics.rate, -1);
  const errorCount = _.defaultTo(metrics.error_rate, -1);
  const duration = _.defaultTo(metrics.response_time, -1);
  const threshold = _.defaultTo(metrics.threshold, -1);

  const selectionStatistics: IntSelectionStatistics = {};

  if (requestCount >= 0.001) {
    selectionStatistics.requests = _formatDecimals(requestCount);
  }
  if (errorCount >= 0) {
    selectionStatistics.errors = _formatDecimals(errorCount);
  }
  if (duration >= 0.001) {
    selectionStatistics.responseTime = _formatDecimals(duration);
    if (threshold >= 0) {
      selectionStatistics.threshold = _.round(threshold);
      selectionStatistics.thresholdViolation = duration > threshold;
    }
  }
  return selectionStatistics;
}

export function createEdgeNodeStatsTable(nodeName: string, edgeMetrics: IntGraphMetrics): TableContent {
  const sendingObject: TableContent = {
    name: nodeName,
    responseTime: '-',
    rate: '-',
    error: '-',
  };

  if (edgeMetrics !== undefined) {
    const { response_time, rate, error_rate } = edgeMetrics;

    if (rate !== undefined) {
      sendingObject.rate = _formatDecimals(rate).toString();
    }
    if (response_time !== undefined) {
      sendingObject.responseTime = _formatDecimals(response_time).toString();
    }
    if (error_rate !== undefined && rate !== undefined) {
      sendingObject.error = Math.floor(error_rate / (rate / 100)) + '%';
    }
  }
  return sendingObject;
}

function _formatDecimals(duration: number) {
  if (duration < 0.1) {
    return _.round(duration, lessThanThousandthsDec);
  } else {
    return _.round(duration, standardDec);
  }
}

function roundPercentageToDecimal(decimal: number, value: string) {
  if (value !== '-') {
    let valueDecimals = _getDecimalsOf(parseFloat(value));
    if (valueDecimals > decimal) {
      value = parseFloat(value).toFixed(decimal) + '%';
    }
  }
  return value;
}

function _getDecimalsOf(value: number) {
  if (Math.floor(value) !== value) {
    return value.toString().split('.')[1].length || 0;
  }
  return 0;
}

export default roundPercentageToDecimal;
