import React from 'react';
import { StandardEditorContext, StandardEditorProps } from '@grafana/data';
import { PanelSettings, DataMapping } from '../../types';
import { Switch } from '@grafana/ui';

interface Props extends StandardEditorProps<boolean, PanelSettings> {
  item: any;
  value: boolean;
  onChange: (value?: boolean) => void;
  context: StandardEditorContext<any>;
}

interface State {
  item: any;
  value: boolean;
  dataMapping: DataMapping | undefined;
  onChange: (value?: boolean) => void;
  context: StandardEditorContext<any>;
}

export class DummyDataSwitch extends React.PureComponent<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    let { dataMapping } = props.context.options;
    if (dataMapping === undefined) {
      dataMapping = props.item.defaultValue;
    }
    this.state = {
      dataMapping: dataMapping,
      ...props,
    };
  }

  getDummyDataMapping = () => {
    return {
      aggregationType: 'path',
      sourceColumn: '',
      targetColumn: 'destination',
      responseTimeColumn: '',
      requestRateColumn: 'Value #Request Rate',
      errorRateColumn: 'Value #Error Rate',
      responseTimeOutgoingColumn: 'Value #Response Time',
      requestRateOutgoingColumn: 'Value #Outbound Request Rate',
      errorRateOutgoingColumn: '',
      displayNameColumn: 'to',
      showDummyData: true,
      baselineRtUpper: '',
      extOrigin: '',
      extTarget: '',
      type: '',
    };
  };

  onChange = () => {
    let { dataMapping } = this.props.context.options;
    const { item } = this.state;
    const { onChange } = this.props;
    const newValue = !dataMapping.showDummyData;

    if (newValue) {
      this.setState({ dataMapping: dataMapping });
      dataMapping = this.getDummyDataMapping();
    }
    dataMapping.showDummyData = newValue;
    onChange.call(item.path, dataMapping);
  };

  render() {
    let { dataMapping } = this.props.context.options;
    if (dataMapping === undefined) {
      dataMapping = this.props.item.defaultValue;
      this.props.context.options.dataMapping = this.props.item.defaultValue;
    }

    return (
      <div>
        <Switch value={dataMapping.showDummyData} onChange={() => this.onChange()} />
      </div>
    );
  }
}
